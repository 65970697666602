import React, { useState, useEffect } from 'react';
import './PropertyCard.css';

function PropertyCard({ property }) {
  return (
    <div className="property-card">
      <img src={property.post_image} alt={property.post_title} />
      <div className="property-info">
        <h3>{property.post_title}</h3>
        <p>{property.address}</p>
        <p>{property.price} night</p>
        <div className="rating">
          <span>★ {property.rating || '4.5'}</span>
        </div>
      </div>
    </div>
  );
}

export default PropertyCard;