import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import FilterBar from './components/FilterBar';
import PropertyList from './components/PropertyList';
import Redirect from './pages/Redirect';
import './App.css';

function App() {
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchProperties();
  }, []);

  const fetchProperties = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://propertify.onrender.com/api/properties/all');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched data:', data); // Log the fetched data
      setProperties(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching properties:', error);
      setError('Failed to fetch properties. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <SearchBar />
          <FilterBar />
          <Routes>
            <Route path="/" element={
              isLoading ? (
                <p>Loading properties...</p>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <PropertyList properties={properties} />
              )
            } />
            <Route path="/redirect" element={<Redirect />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;