import React from 'react';
import PropertyCard from './PropertyCard.js';
import './PropertyList.css';

function PropertyList({ properties }) {
  return (
    <div className="property-list">
      {properties.map(property => (
        <PropertyCard key={property._id} property={property} />
      ))}
    </div>
  );
}

export default PropertyList;