import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Redirect() {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let path = params.get('path') || '';
    
    // If the path is not provided, construct it from the current URL
    if (!path && location.pathname !== '/redirect') {
      path = location.pathname.slice(1) + (location.search ? location.search : '');
    }

    const deepLink = `ofo://${path}`;
    const playStoreUrl = `https://play.google.com/store/apps/details?id=${params.get('apn') || 'com.ofo.nexthunt'}&referrer=${encodeURIComponent(`ofo://${path}`)}`;
    const fallbackUrl = `${window.location.origin}/fallback?${new URLSearchParams({...Object.fromEntries(params), path}).toString()}`;

    // Update meta tags for better sharing experience
    updateMetaTags(params);

    // Step 1: Attempt to open the app
    window.location.href = deepLink;

    // Step 2: If the app doesn't open within 1000ms, redirect to Play Store
    const timer = setTimeout(() => {
      window.location.href = playStoreUrl;
    }, 1000);

    // Step 3: If Play Store redirect doesn't work (e.g., on desktop), fallback to web page
    const fallbackTimer = setTimeout(() => {
      window.location.href = fallbackUrl;
    }, 2000);

    return () => {
      clearTimeout(timer);
      clearTimeout(fallbackTimer);
    };
  }, [location]);

  function updateMetaTags(params) {
    const title = params.get('title') || 'Exciting Property on OFO Space!';
    const description = params.get('desc') || 'Check out this amazing property on OFO Space. Tap to view details!';
    const image = params.get('img') || 'https://ofospace.com/default-property-image.jpg';

    document.title = title;
    document.querySelector('meta[property="og:title"]')?.setAttribute("content", title);
    document.querySelector('meta[property="og:description"]')?.setAttribute("content", description);
    document.querySelector('meta[property="og:image"]')?.setAttribute("content", image);
    // Update Twitter meta tags similarly
  }

  return (
    <div>
      <h1>Redirecting to OFO Space App</h1>
      <p>If you're not redirected automatically, click the button below:</p>
      <button onClick={() => window.location.href = `ofo://${new URLSearchParams(location.search).get('path') || ''}`}>
        Open OFO Space App
      </button>
    </div>
  );
}

export default Redirect;